import { render, staticRenderFns } from "./AprovacaoLista.vue?vue&type=template&id=88cc0516&scoped=true&"
import script from "./AprovacaoLista.vue?vue&type=script&lang=js&"
export * from "./AprovacaoLista.vue?vue&type=script&lang=js&"
import style0 from "./AprovacaoLista.vue?vue&type=style&index=0&id=88cc0516&prod&lang=scss&scoped=true&"
import style1 from "./AprovacaoLista.vue?vue&type=style&index=1&id=88cc0516&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88cc0516",
  null
  
)

export default component.exports